import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export class CreditCardValidator {
  static creditCardNumberValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      if (!value) {
        return null; // Don't validate empty value
      }

      // Remove all whitespaces
      const sanitizedValue = value.replace(/\s+/g, '');

      // Define card type patterns
      const cardPatterns = {
        visa: /^4[0-9]{12}(?:[0-9]{3})?$/,
        mastercard: /^5[1-5][0-9]{14}$/,
        americanExpress: /^3[47][0-9]{13}$/,
        dinersClub: /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/,
        discover: /^6(?:011|5[0-9]{2})[0-9]{12}$/,
        unionPay: /^62[0-9]{14,17}$/,
        jcb: /^(?:2131|1800|35\d{3})\d{11}$/,
        maestro: /^(?:5[0678]\d\d|6304|6390|67\d\d)\d{8,15}$/,
        forbrugsforeningen: /^600722[0-9]{10}$/,
        dankort: /^5019[0-9]{12}$/,
      };

      const isValidPattern = Object.values(cardPatterns).some(pattern => pattern.test(sanitizedValue));

      // Validate Luhn algorithm on the entire number
    //   const isValidLuhn = this.luhnCheck(sanitizedValue);

      if (isValidPattern) {
        return null; // Valid card
      } else {
        return { invalidCreditCard: true }; // Invalid card
      }
    };
  }

  private static luhnCheck(value: string): boolean {
    let sum = 0;
    let shouldDouble = false;

    // Loop through values starting at the rightmost side
    for (let i = value.length - 1; i >= 0; i--) {
      let digit = parseInt(value.charAt(i), 10);

      if (shouldDouble) {
        digit *= 2;
        if (digit > 9) {
          digit -= 9;
        }
      }

      sum += digit;
      shouldDouble = !shouldDouble;
    }

    return (sum % 10) === 0;
  }
}
