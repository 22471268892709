<div class="modal-header border-0">
    <h4 class="modal-title">ไม่สามารถเปิดใช้งานบัตรได้</h4>
  </div>
  <div class="modal-body">คุณต้องมีอายุต่ำกว่า 23 ปี <br>
    ตามเงื่อนไขการใช้งานบัตร Play Student
  <div class="modal-footer border-0">
    <button
      type="button"
      class="btn btn-default submitStyle"
      (click)="closeModal()"
    >
      ตกลง
    </button>
  </div>
  