<div class="modal-header border-0">
  <h4 class="modal-title">
    รหัส​บัตรของคุณคาดเดาง่ายเกินไป
  </h4>
</div>
<div class="modal-body">
  <span>คุณสามารถใช้รหัสนี้ หรือตั้งรหัสใหม่ <br> เพื่อเพิ่มความปลอดภัยให้กับบัตรของคุณ</span>
</div>
<div class="modal-footer border-0">
  <div class="row">
    <div class="col-6">
      <button
        name="btnReset"
        type="button"
        class="btn btn-default btn-style"
        (click)="resetClicked()"
      >
        ตั้งรหัสใหม่
      </button>
    </div>
    <div class="col-6">
      <button
        name="btnContinue"
        type="button"
        class="btn btn-default submitStyle"
        (click)="continueClicked()"
      >
        ใช้รหัสนี้
      </button>
    </div>
  </div>
</div>
