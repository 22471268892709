<div class="header-box fixed-top">
  <div class="vectorOnHeaderBox">
    <div class="row">
      <div
        class="col-xs-2 col-2"
        (click)="backClicked()"
        style="line-height: 30px"
      >
        <img src="/assets/BackIcon.png" height="20" />
      </div>
      <div class="col-xs-8 col-8 text-center page-header">ยืนยันข้อมูลบัตร</div>
      <div class="col-xs-2 col-2"></div>
    </div>
  </div>
</div>
<div class="leftCorner"><img src="/assets/LeftCorner.png" height="12" /></div>
<div class="rightCorner"><img src="/assets/RightCorner.png" height="12" /></div>
<div class="page-body unselectable">
  <div class="pad16">
    <div style="height: 100px"></div>
    <img *ngIf="cardLookupResponse.cardType == 'PLAYCARD'" src="/assets/PaotangPayPlay.png" class="card-Image" />
    <img *ngIf="cardLookupResponse.cardType == 'BEM_ELDER'" src="/assets/ElderCard.png" class="card-Image" />
    <img *ngIf="cardLookupResponse.cardType == 'BEM_STUDENT'" src="/assets/StudentCard.png" class="card-Image" />
    <div class="sensitiveBox">
    <p *ngIf="cardLookupResponse.cardType == 'PLAYCARD'" class="sectionHeader">ข้อมูลบัตร Play</p>
    <p *ngIf="cardLookupResponse.cardType == 'BEM_ELDER'" class="sectionHeader">ข้อมูลบัตร Play Elder</p>
    <p *ngIf="cardLookupResponse.cardType == 'BEM_STUDENT'" class="sectionHeader">ข้อมูลบัตร Play Student</p>
    <mat-grid-list cols="2" rowHeight="32" >
      <mat-grid-tile>
        <div class="cardInfoHeader">เลขบัตร</div>
      </mat-grid-tile>
      <mat-grid-tile>
        <div class="cardInfoDetail">
          <!-- {{ getFormattedCardNumber(cardLookupResponse.maskCardNo) }} -->
          {{ cardLookupResponse.maskCardNo }}
        </div>
      </mat-grid-tile>
      <mat-grid-tile>
        <div class="cardInfoHeader">วันหมดอายุบัตร</div>
      </mat-grid-tile>
      <mat-grid-tile>
        <div class="cardInfoDetail">{{ expDate }}</div>
      </mat-grid-tile>
      <mat-grid-tile>
        <div class="cardInfoHeader">ชื่อเรียกบัตร</div>
      </mat-grid-tile>
      <mat-grid-tile>
        <div class="cardInfoDetail">{{ cardNickName }}</div>
      </mat-grid-tile>
      <mat-grid-tile>
        <div class="cardInfoHeader">ชื่อผู้ถือบัตร</div>
      </mat-grid-tile>
      <!-- <mat-grid-tile>
        <div class="cardInfoDetail">{{ firstName }} {{ lastName }}</div>
      </mat-grid-tile>
      <mat-grid-tile>
        <div class="cardInfoHeader">ประเภทบัตร</div>
      </mat-grid-tile>
      <mat-grid-tile>
        <div class="cardInfoDetail">{{ firstName }} {{ lastName }}</div>
      </mat-grid-tile>
      <mat-grid-tile>
        <div class="cardInfoHeader">ประเภทส่วนลด</div>
      </mat-grid-tile> -->
      <mat-grid-tile>
        <div class="cardInfoDetail">{{ firstName }} {{ lastName }}</div>
      </mat-grid-tile>
      <!-- <mat-grid-tile>
        <div class="cardInfoHeader">นามสกุล</div>
      </mat-grid-tile>
      <mat-grid-tile>
        <div class="cardInfoDetail">{{ lastName }}</div>
      </mat-grid-tile> -->

      <mat-grid-tile>
        <div class="cardInfoHeader">วงเงินใช้จ่ายต่อวัน</div>
      </mat-grid-tile>
      <mat-grid-tile>
        <div class="cardInfoDetail">
          {{ cardDetails.cardUsageLimitSpending }} บาท
        </div>
      </mat-grid-tile>

      <!-- <mat-grid-tile
      [ngClass]="{
        'd-none': cardDetails.cardUsageLimitWithdrawATM === '0'
      }"
    >
      <div class="cardInfoHeader">วงเงินกดเงินสดต่อวัน</div>
    </mat-grid-tile>
    <mat-grid-tile
      [ngClass]="{
        'd-none': cardDetails.cardUsageLimitWithdrawATM === '0'
      }"
    >
      <div class="cardInfoDetail">
        {{ cardDetails.cardUsageLimitWithdrawATM }} บาท
      </div>
    </mat-grid-tile> -->


    </mat-grid-list>

  </div>
  <div class="infoBox row" *ngIf="cardLookupResponse.cardBalanceStatus == 'SUFFICIENT_AMOUNT'">
    <div class="col-1" style="padding: 2px;"> 
      <img src="/assets/ic_info-blue.png" height="16" style="vertical-align: text-top" />
    </div>
    <span class="col-11" style="padding: 0;color: #2B77FF; font-size: 16px; font-weight: 400;">หากมียอดเงินคงเหลือในบัตร คุณจำเป็นต้องโอนเงินเข้าเป๋าตังเปย์ในขั้นตอนถัดไป</span>
  </div>

  <div class="container sensitivebox-step" *ngIf="cardLookupResponse.cardBalanceStatus == 'INSUFFICIENT_AMOUNT'" style="margin-bottom: 120px !important;">
    <!-- completed -->
    <p class="sectionHeader" style="padding: 12px 12px 0px 12px;">บัตรของคุณมียอดคงค้าง กรุณาทำตามขั้นตอนดังนี้</p>
    <div class="step completed">
      <div class="v-stepper">
        <div class="circle">1</div>
        <div class="line"></div>
      </div>

      <div class="content">
        ชำระยอดคงค้างในขั้นตอนถัดไป
      </div>
    </div>

    <!-- active -->
    <div class="step completed">
      <div class="v-stepper">
        <div class="circle">2</div>
        <div class="line"></div>
      </div>

      <div class="content">
        เมื่อชำระและเปิดใช้งานบัตรสำเร็จ นำไปบัตรไปแตะที่ประตูอัตโนมัติของสถานี MRT เพื่อให้ระบบอัปเดตข้อมูล
      </div>
    </div>

    <!-- empty -->
    <div class="step completed">
      <div class="v-stepper">
        <div class="circle">3</div>
        <div class="line"></div>
      </div>

      <div class="content">
        คุณสามารถใช้งานบัตรที่สถานี MRT ได้อีกครั้งหลังจาก 30 - 60 นาที
      </div>
    </div>

    <!-- regular -->

  <!-- </div>
    <div style="word-wrap: break-word;">
      cardDetails: {{ getObject(cardDetails) }}
    </div>
    <div style="word-wrap: break-word;">
      cardLookupResponse: {{ getObject(cardLookupResponse) }}
    </div> -->

  </div>

</div>

<div class="actionArea fixed-bottom d-grid">
  <button class="btn submitStyle" [style]="safeBottom" (click)="submitCard()">
    ยืนยัน
  </button>
</div>
