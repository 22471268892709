import { session } from './../interfaces/get-token.Auth';
import { Detail, LookupRequest, LookupResponse, SensitiveCardDetailResponse } from './../interfaces/detail.Card';
import { Injectable } from '@angular/core';
import { Observable, firstValueFrom } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { SessionStatus } from '../interfaces/session-status';
import { secretbox, box, randomBytes } from "tweetnacl";
import {
  decodeUTF8,
  encodeUTF8,
  encodeBase64,
  decodeBase64
} from "tweetnacl-util";
import { BsComponentRef } from 'ngx-bootstrap/component-loader';
import { log } from '../utilities';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  })
};

@Injectable({
  providedIn: 'root'
})
export class CardDetailService {

  public apiURL: string = '';
  public mPrivateKey: any;
  public tempPublicKey: any;
  constructor(private _httpClient: HttpClient) {
    this.apiURL = environment.apiUrl;
  }

  // getCardDetail(): Observable<Detail> {
  //   return this._httpClient.post<Detail>('/card-platform/v1/inquiry/card/detail', httpOptions)
  // }
  getCardDetail(cardRefNo: string, authToken: string, webviewToken: string, sid: string): any {
    let httpOptionsWithSession = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'sessionId': sid
      })
    }
    return this._httpClient.post<Detail>(this.apiURL + '/card-platform/v1/inquiry/card/detail', { cardRefNo }, httpOptionsWithSession)

  }
  generateToken(authorizationCode: string): Observable<SessionStatus> {
    return this._httpClient.post<SessionStatus>(this.apiURL + '/card-platform/v1/oauth/token/generate', { authorizationCode }, httpOptions);
  }
  updateCardNickName(cardRefNo: string, cardNickName: string, sid: string): Observable<any> {
    let httpOptionsWithSession = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'sessionId': sid
      })
    }
    return this._httpClient.post<any>(this.apiURL + '/card-platform/v1/inquiry/update/card/nickname', { cardRefNo, cardNickName }, httpOptionsWithSession)
  }

  updateCardNickNameAsync(cardRefNo: string, cardNickName: string, sid: string) {
    let httpOptionsWithSession = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'sessionId': sid
      })
    }
    return firstValueFrom(this._httpClient.post<any>(this.apiURL + '/card-platform/v1/inquiry/update/card/nickname', { cardRefNo, cardNickName }, httpOptionsWithSession))
  }

  cardLookup(LookReq: LookupRequest, sid: string): Observable<LookupResponse> {
    let httpOptionsWithSession = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'sessionId': sid,
      })
    }
    return this._httpClient.post<any>(this.apiURL + '/card-platform/v1/inquiry/card/lookup', LookReq, httpOptionsWithSession);
  }

  generateKey(): Observable<any> {
    let sessionId = sessionStorage.getItem('sessionID');
    let httpOptionsWithSession = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'sessionId': sessionId ?? '',
      })
    }
    const genKeyPair = box.keyPair();
    this.mPrivateKey = genKeyPair.secretKey;
    //this.tempPublicKey = genKeyPair.publicKey;
    let mPublicKey = encodeBase64(genKeyPair.publicKey);
    return this._httpClient.post<any>(this.apiURL + '/card-platform/v1/inquiry/key/generate', { sessionId, mPublicKey }, httpOptionsWithSession);
  }
  async generateSecretKey() {
    let sPublicKey: any;
    let secretKey: any;
    let k = await firstValueFrom(this.generateKey());
    sPublicKey = k.sPublicKey;
    this.tempPublicKey = sPublicKey;
    secretKey = box.before(decodeBase64(sPublicKey), this.mPrivateKey);
    return secretKey;
  }
  getSensitiveCardDetails(cardRefNo: string, sid: string): Observable<SensitiveCardDetailResponse> {
    let httpOptionsWithSession = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'sessionId': sid
      })
    }
    return this._httpClient.post<any>(this.apiURL + '/card-platform/v1/inquiry/card/detail/sensitive', { cardRefNo }, httpOptionsWithSession);
  }

  decryptSensitiveCardDetails(secretKey: Uint8Array, data: SensitiveCardDetailResponse) {
    const decryptSensitive = (messageWithNonce: string) => {
      const messageWithNonceAsUint8Array = decodeBase64(messageWithNonce);
      const nonce = messageWithNonceAsUint8Array.slice(0, box.nonceLength);
      const message = messageWithNonceAsUint8Array.slice(
        box.nonceLength,
        messageWithNonceAsUint8Array.length
      );
      const decrypted = box.open.after(message, nonce, secretKey);
      if (!decrypted) {
        throw new Error('Could not decrypt message');
      }

      const enc = encodeUTF8(decrypted);
      return enc;
    }

    log("data.cardEmbossName", data.cardEmbossName)

    let objDecryptedSensitiveCardDetails = {
      encCardNo: decryptSensitive(data.cardNo),
      encExpiryDate: decryptSensitive(data.cardExpiryDate),
      encCvv: decryptSensitive(data.cardCvv),
      encEmbossName: data.cardEmbossName !== undefined ? decryptSensitive(data.cardEmbossName) : '',
    }
    return objDecryptedSensitiveCardDetails;
  }
}
