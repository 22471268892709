import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-pop-up-error-invalid',
  templateUrl: './pop-up-error-invalid.component.html',
  styleUrls: ['./pop-up-error-invalid.component.css']
})
export class PopUpErrorInvalidComponent implements OnInit {

  constructor(public bsModalRef: BsModalRef) { }

  ngOnInit(): void {
  }


  closeModal() {
    location.reload()
    this.bsModalRef.hide()
  }


}
