<div class="modal-header border-0">
    <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="bsModalRef.hide()"></button>
  </div>
  
    <div class="modal-body">
      <img src="/assets/BindCredit.png" class="card-step-image" />
      <h4 class="modal-title">ขั้นตอนง่ายๆ ในการเปิดใช้งานบัตร</h4>
      <div class="container sensitiveBox">
        <!-- completed -->
        <div class="step completed">
          <div class="v-stepper">
            <div class="circle">1</div>
            <div class="line"></div>
          </div>
  
          <div class="content">
            ระบุข้อมูลบัตรที่ต้องการเปิดใช้งาน
          </div>
        </div>
  
        <!-- active -->
        <div class="step completed">
          <div class="v-stepper">
            <div class="circle">2</div>
            <div class="line"></div>
          </div>
  
          <div class="content">
            ตั้งรหัสบัตร 6 หลัก สำหรับใช้งานกับ <br> เครื่องรูดบัตรที่จุดชำระเงิน
          </div>
        </div>
  
        <!-- empty -->
        <div class="step completed">
          <div class="v-stepper">
            <div class="circle">3</div>
            <div class="line"></div>
          </div>
  
          <div class="content">
            โอนยอดเงินคงเหลือในบัตรไปยังเป๋าตังเปย์ หรือชำระยอดคงค้างบัตร (ถ้ามี)
          </div>
        </div>
  
        <!-- regular -->
        <div class="step completed">
          <div class="v-stepper">
            <div class="circle">4</div>
            <div class="line"></div>
          </div>
  
          <div class="content">
            เริ่มใช้งานบัตรได้เลย!
          </div>
        </div>
  
      </div>
      <div class="modal-footer border-0">
        <button type="button" class="btn btn-default submitStyle" (click)="bsModalRef.hide()">
          ตกลง
        </button>
    </div>
  
  </div>