<div class="modal-header border-0">
    <h4 class="modal-title">ไม่สามารถเปิดใช้งานบัตรได้</h4>
  </div>
  <div class="modal-body">คุณต้องมีอายุ 60 ปีขึ้นไป <br> ตามเงื่อนไขการใช้งานบัตร Play Elder
  <div class="modal-footer border-0">
    <button
      type="button"
      class="btn btn-default submitStyle"
      (click)="closeModal()"
    >
      ตกลง
    </button>
  </div>
  