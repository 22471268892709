import { CardInfo, LookupResponse } from './../interfaces/detail.Card';
import { map } from 'rxjs/operators';
import { tap } from 'rxjs';
import { CardDetailService } from './../services/card-detail.service';
import jwt_decode from "jwt-decode";
import { Router, ActivatedRoute } from '@angular/router';
import { DatePipe, Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import isOnline from 'is-online';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { PopUpNoInternetComponent } from '../pop-up-no-internet/pop-up-no-internet.component';

@Component({
  selector: 'app-card-bind-confirm',
  templateUrl: './card-bind-confirm.component.html',
  styleUrls: ['./card-bind-confirm.component.css']
})
export class CardBindConfirmComponent implements OnInit {
  decodedwebViewToken: string = "";
  webviewToken: string = "";
  authToken: string = "";
  sid: string = "";
  cardRefNo: string = "";
  cardDetails: CardInfo = {} as CardInfo;
  networkStatus: boolean = false;
  bsModalRef?: BsModalRef;
  firstName: string = "";
  lastName: string = "";
  expDate: string = "";
  cardNickName: string = "";
  creditCardNum: string = "";
  cardNum:string="";
  public safeBottom: any;
  public cardLookupResponse: LookupResponse = {} as LookupResponse;
  constructor(
    public _location: Location,
    public _router: Router,
    public _cardDetailService: CardDetailService,
    private activatedRoute: ActivatedRoute,
    private datePipe: DatePipe,
    private modalService: BsModalService,
  ) {
    this.activatedRoute.queryParams.subscribe(prm => {
      this.authToken = prm['authorizationCode'];
      this.cardRefNo = prm['cardRefNo'];
      this.webviewToken = prm['webviewToken'];
      this.cardNickName = prm['cardnickname'];
    });
    var state = this._location.getState() as { cardLookupResponse: LookupResponse, cardNum: string};
    this.cardLookupResponse = state.cardLookupResponse
    console.log(this.cardLookupResponse)
    console.log(this.cardNickName)
    this.creditCardNum = state.cardNum;
  }

  ngOnInit(): void {
    var userAgent = navigator.userAgent || navigator.vendor;
    if (/android/i.test(userAgent)) {
      this.safeBottom = "margin-bottom: 16px"
    } else {
      this.safeBottom = "margin-bottom: 42px"
    }
    (async () => { this.networkStatus = await isOnline(); })();
    // const token = this._cardDetailService.generateToken(this.authToken);
    // token.pipe(
    //   tap(sd => { this.sid = sd.sessionId; }),
    //   map(sd => { this.sid = sd.sessionId; })
    // ).subscribe(sd => {
    if (this.cardLookupResponse.cardType != "PLAYCARD") {
      this.firstName = this.cardLookupResponse.firstName;
      this.lastName = this.cardLookupResponse.lastName;
      this.cardDetails.cardUsageLimitWithdrawATM = this.cardLookupResponse.cardUsageLimitWithdrawATM ?? '0';
      this.cardDetails.cardUsageLimitSpending = this.cardLookupResponse.cardUsageLimitSpending ?? '0';
      console.log(this.cardDetails)
      var pattern = /(\d{4})(\d{2})(\d{2})/;
      var smdate = new Date(this.cardLookupResponse?.cardExpiryDate.replace(pattern, '$1-$2-$3'));
      this.expDate = this.datePipe.transform(smdate, 'MM / yy')?.toString() ?? "";
      this.cardDetails.maskCardNo = this.cardLookupResponse.maskCardNo ?? '*********';
    } else {
      const cds = this._cardDetailService.getCardDetail(this.cardRefNo, this.authToken, this.webviewToken, this.sid)
      cds.subscribe((dc: CardInfo) => {
        this.cardDetails = dc;
        this.cardDetails.cardNo = this.creditCardNum;
        this.firstName = this.cardDetails.cardHolderNameTH.split(' ')[0];
        this.lastName = this.cardDetails.cardHolderNameTH.split(' ')[1];
        this.cardDetails.cardUsageLimitWithdrawATM = this.cardDetails.cardUsageLimitWithdrawATM ?? '0';
        var pattern = /(\d{4})(\d{2})(\d{2})/;
        var smdate = new Date(this.cardDetails?.cardExpiryDate.replace(pattern, '$1-$2-$3'));
        this.expDate = this.datePipe.transform(smdate, 'MM / yy')?.toString() ?? "";
      });
      this.decodedwebViewToken = jwt_decode(this.webviewToken);
    }

    //});
  }
  backClicked() {
    this._location.back();
  }
  async submitCard() {
    this.networkStatus = await isOnline();
    if (this.networkStatus) {
      this._router.navigate(['/atm-set-pin'], {
        state: {
          cardInfo: this.cardDetails,
          cardnickname: this.cardNickName,
          cardLookupResponse: this.cardLookupResponse,
          cardNum: this.creditCardNum
        },
        queryParams: {
          sessionId: this.sid,
          cardRefNo: this.cardRefNo,
          webviewToken: this.webviewToken,
          authorizationCode: this.authToken
        }
      });
    } else {
      this.bsModalRef = this.modalService.show(PopUpNoInternetComponent, { class: 'modal-dialog-centered' });
    }
  }
  getFormattedCardNumber(cardNo: string) {
    let cn = cardNo?.match(/.{1,4}/g);
    return cn?.join(" ")
  }
  getObject(object:any) {
    return JSON.stringify(object)

  }
}
