import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-pop-up-step-bind-card',
  templateUrl: './pop-up-step-bind-card.component.html',
  styleUrls: ['./pop-up-step-bind-card.component.css']
})
export class PopUpStepBindCardComponent implements OnInit {

  constructor(public bsModalRef: BsModalRef) { }
  ngOnInit(): void {
  }

}
