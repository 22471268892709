<div class="modal-header border-0">
  <h4 class="modal-title">
    รหัสบัตรไม่ถูกต้อง<br>เกินจำนวนครั้งที่กำหนด
  </h4>
</div>
<div class="modal-body">กรุณาตั้งรหัสบัตรใหม่</div>
<div class="modal-footer border-0">
  <div class="row">
    <div class="col-12">
      <button
        name="btnReset"
        type="button"
        class="btn btn-default submitStyle"
        (click)="bsModalRef.hide()"
      >
        ตั้งรหัสใหม่
      </button>
    </div>
  </div>
</div>
