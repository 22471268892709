import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TnCStateService {

  private subject = new ReplaySubject<Boolean>();
  private subject$ = this.subject.asObservable();
  constructor() {
  }

  setTnC(form: Boolean) {
    this.subject.next(form);
  }

  getTnC(): Observable<any> {
    return this.subject$;
  }
}
